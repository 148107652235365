<template>
  <va-card v-if="showGrid" title="platform kyc">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="hideGrid()">Add</va-button>
      <va-button color="orange" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>

    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="platformKyc"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="status" slot-scope="props">
        <i v-if="props.status == 1" class='fa fa-check-circle status-true'/>
        <i v-else class='fa fa-times-circle status-false'/>
      </template>
      <template slot="edit" slot-scope="props">
        <va-button flat small
          color="gray" icon="fa fa-pencil"
          @click="editKyc(props.rowData)"
          class="ma-0">
        </va-button>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create platform kyc':'update platform kyc'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addKyc():updateKyc()">
      <va-select
        v-if="isCreate"
        label="Platform Name *"
        v-model="stPlatform"
        searchable
        :options="platformOtns"
        placeholder="Enter Platform Name"
        textBy="name"
        required="true"
        noClear
      />
      <va-input
        v-else
        label="Platform Name *"
        v-model="stPlatform.name"
        placeholder="Enter Platform Name"
        required="true"
        :disabled="true"/>
      <div
        class="fields"
        v-for="(field,i) in kyc.custom_fields"
        :key="i">
        <div>
          <va-input
            label="Label *"
            placeholder="Select Label"
            v-model="field.label"
            required="true"/>
        </div>
        <div>
          <va-input
            label="Key *"
            placeholder="Enter Key"
            v-model="field.key"
            required="true"/>
        </div>
        <div>
          <va-select
            label="Type *"
            placeholder="Enter type"
            v-model="field.type"
            :options="typeOtns"
            required="true"  
            @keypress="test()"
            noClear/>
        </div>
        <div>
          <va-input
            v-if="field.type !== 'BOOLEAN'"
            label="Default Value" 
            placeholder="Enter Default Value"
            v-model="field.default_values"
          />
          <va-checkbox
            v-else
            label="Default Value"
            v-model="field.checked"
          />
        </div>
        <va-button
          class="content_width toCenter"
          flat color="primary"
          @click.prevent="fields_length++"
          icon="entypo entypo-plus" />
        <va-button
          v-if="kyc.custom_fields.length != 1"
          class="content_width toCenter"
          flat color="danger"
          @click.prevent="removeField(i)"
          icon="entypo entypo-cancel" />
      </div>
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)

export default {
  created () {
    this.getKycs()
    this.getAllPlatform()
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      showGrid: true,
      isCreate: true,
      platformOtns: [],
      stPlatform: '',
      typeOtns: ['TEXT', 'DROPDOWN', 'PASSWORD',"BOOLEAN"],
      platform_id: '',
      kycs: [],
      fields_length: 0,
      kyc: {
        platform_id: '',
        platform_name: '',
        custom_fields: [],
      },
    }
  },
  watch: {
    stPlatform (val) {
      this.kyc.platform_id = val.id
      this.kyc.platform_name = val.name
    },
    fields_length () {
      this.kyc.custom_fields.push({
        label: '',
        key: '',
        type: '',
        default_values: '',
        checked: false,
      })
    },
  },
  computed: {
    platformKyc () {
      return [
        {
          name: 'sno',
        },
        {
          name: 'platform_name',
          title: 'Platform Name',
        },
        {
          name: '__slot:edit',
          dataClass: 'text-right',
        },
      ]
    },
    filteredData () {
      const filteredPlatform = search(this.term, this.kycs)
      return filteredPlatform
    },
  },
  methods: {
    hideGrid () {
      this.showGrid = false
      this.fields_length++
      this.filterPlatform()
    },
    filterPlatform () {
      this.platformOtns = this.platformOtns.filter(platform =>
        !this.kycs.find(kyc => platform.id == kyc.platform_id))
    },
    removeField (index) {
      this.kyc.custom_fields = this.kyc.custom_fields.filter((_field, i) => i != index)
    },
    exportData () {
      var arr = []
      this.kycs.map(function (item) {
        var json = JSON.parse(item.custom_fields)
        json.map(function (entity) {
          arr.push({"platform_name":item.platform_name,"label":entity.label,"key":entity.key,"type":entity.type,"default_values":entity.default_values})

        })
      })
      var rowKeys = {
        platform_name: 'Platform Name',
        label: 'Label',
        key: 'Key',
        type: 'Type',
        default_values:'Default Values'
      }
      return exportToFile(arr, rowKeys, 'PlatformKYC', 'CSV')
    },
    editKyc (kyc) {
      this.platform_id = kyc.platform_id
      this.$http.get(config.menu.host + `platform_kyc/${kyc.platform_id}`)
        .then(res => {
          this.kyc.custom_fields = JSON.parse(res.data[0].custom_fields)
          this.stPlatform = {
            id: res.data[0].platform_id,
            name: res.data[0].platform_name,
          }
          this.filterPlatform()
          this.platformOtns.push(this.stPlatform)
        })
      this.showGrid = false
      this.isCreate = false
    },
    resetValue () {
      this.showGrid = true
      this.isCreate = true
      this.stPlatform = ''
      Object.entries(this.kyc).forEach(key => {
        if (key[0] == 'custom_fields') this.kyc[key[0]] = []
        else this.kyc[key[0]] = ''
      })
    },
    validateKyc () {
      let validation = this.kyc.custom_fields.every(
        fields => {
          if (!this.stPlatform) return false
          if (fields.type == 'BOOLEAN') {
            fields.default_values = ""
            return true
          }
          for (const key in fields) {
            if((key != "checked") && ((key != 'default_values') && (fields[key] == ""))) return false
          }
          return true
        }
      )
      return validation
    },
    getKycs () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + 'platform_kyc')
      .then(res => {
        loader.hide()
        this.kycs = res.data
        this.kycs.map((kyc, i) => kyc.sno = i + 1)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({ text: error.body, type: 'error' })
      })
    },
    getAllPlatform () {
      this.$http.get(config.menu.host + 'platform')
        .then(res => {
          res.data.forEach(platform => this.platformOtns.push({
            id: platform.platform_id,
            name: platform.platform_name,
          }))
        })
    },
    addKyc () {
      if (!this.validateKyc()) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + 'platform_kyc', this.kyc)
        .then(res => {
          loader.hide()
          Vue.notify({ text: res.body, type: 'success' })
        })
        .catch(err => {
          loader.hide()
          Vue.notify({text: err.body, type: 'error'})
        }).then(() => {
          this.resetValue()
          this.getKycs()
        })
    },
    updateKyc () {
      if (!this.validateKyc()) return
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + `platform_kyc/${this.platform_id}`, this.kyc)
        .then(function(res){
          loader.hide()
          Vue.notify({text: res.body, type: 'success'})
          this.resetValue()
          this.getKycs()
        },function(err) {
          loader.hide()
          Vue.notify({text: err.body,type: 'error'})
        })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>

<style scoped>
.badge {
  padding: 8px 0.5rem;
}

.status-true {
  color: green;
  padding-left: 1rem;
}

.status-false {
  color: red;
  padding-left: 1rem;
}

.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.toCenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content_width {
  width: 30px;
  height: 30px;
}
</style>
Cancel